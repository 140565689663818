<template>

  <v-app id="inspire" translate="no" class="notranslate">

   
    <!-- <br/><br/><br/> <span class="ml-10 pl-10"> {{ dados_cliente }} </span> -->
    <!-- <br/><br/><br/> <span class="ml-10 pl-10"> {{ assinaturas }} </span> -->
    <!-- <br/><br/><br/> <span class="ml-10 pl-10"> {{ cliente_situacao }} </span> -->
    <!-- <br/><br/><br/> <span class="ml-10 pl-10"> {{ dias_testando }} </span> -->
    <!-- <br/><br/><br/> <span class="ml-10 pl-10"> {{ show_boleto }} </span> -->

 




    <v-navigation-drawer
      v-model="drawer"
      mini-variant
      :app ="true"
      :permanent="false"
      color = "grey lighten-4"
      disable-resize-watcher
      touchless
    >
    <!-- :expand-on-hover="$vuetify.breakpoint.lgAndUp"   -->
  
  

    
      <v-list-item class="px-2">
        <!-- <v-img
          :src="src_img"
          @click="navega()"
          link
        ></v-img>    -->

        <v-card
          height="40"
          width="60"
          class="blue white--text text-center "
          elevation="0"
          @click="navega()"

        >
          <div class="pa-0 mt-2">
            <v-row dense class="grey pa-0 ma-0">
              <v-col class="pa-0 px-1 blue">
                <span class="font_dsi">DSi</span>
              </v-col>

              <!-- <v-col class="pa-0 px-1 white">
                <span class="font-weight-regular pa-0 ma-0 text-caption grey--text">MedClinic</span>
              </v-col> -->
            </v-row>
          </div>
        </v-card>

      </v-list-item>

      <v-divider></v-divider>




      <!-- passo 2 -->
      <v-list
        dense
        nav
      >
        <v-list-item
          v-for= "(item, i) in items"
          :key= "i"
          :to = "item.to"
          link
          active-class="blue--text" 
        >
        <!-- :key= "item.title" -->

          <v-list-item-icon
          v-if="isMobile"
          >
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>


          <v-tooltip bottom
          v-else
          >
            <template v-slot:activator="{ on, attrs }">
                  <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="item.color"
                >
                  {{ item.icon }}
                </v-icon>
            </template>
            <span> {{ item.title }}</span>
          </v-tooltip>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <!-- :class="[item.color+'--text'] -->
          </v-list-item-content>
        </v-list-item>

      <v-divider class="mx-0"></v-divider>
      &nbsp
      <div v-if="SOUoA==''" class="caption py-0"  style="font-size: 0.65rem !important;"> {{ versao }} </div> 
      <div v-else class="caption red--text py-0"  style="font-size: 0.65rem !important;"> {{ versao }}</div> 

      <a class="caption grey--text py-0 my-0"  style="font-size: 0.65rem !important;"
        @click="atualiza_pagina_F5()">
        atualizar
      </a> 

      
      </v-list>
    </v-navigation-drawer>

      <v-app-bar 
      v-model="app_bar"
      :app = "true"
      color="blue"
      height="45"
      :hidden="esconde"
      >
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      


        <v-card
          height=""
          class="grey white--text text-center ml-0  pa-0"
          elevation="4"
          v-if="(!isMobile)"
        >
          <div class="pa-0">
            <v-row dense class="grey lighten-3 pa-0 ma-0">
              <v-col class="pa-0 px-1 blue lighten-0">
                <span class="font-weight-regular pa-0 ml-1 text-h5">DSi</span>
              </v-col>

              <v-col class="pa-0 px-1 grey lighten-3 mt-1">
                <span class="font-weight-regular pa-0 ma-0 text-h7 black--text">CFC</span>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <v-card class="ml-2" v-if="(!isMobile)">
          <!-- EMPURRA -->
        </v-card>

        <v-card
          height=""
          elevation="0"
          class="blue lighten-0 white--text text-center   pa-1 px-2"
        >
          <h5 v-if="isMobile" class="font-weight-regular" >{{ (empresa) }}</h5>
          <h3 v-else class="font-weight-regular">{{ (empresa) }}</h3>

        </v-card>
      


      <v-spacer></v-spacer>



  
      

      <v-chip v-if="(cliente_situacao!='ATIVO')"
        class="mr-2"  dark
        :color="(cliente_situacao == 'TESTE') ? 'orange' :'' "
      >
      {{ cliente_situacao }}  
      <span class="fs-11 ml-1">{{ dias_testando }} dias </span>
      
      </v-chip>





      <v-badge
        :content="dias_dif_aqui"
        :value="dias_dif_aqui"
        color="red"
        overlap
        :dot="(isMobile)"
        :class="[(dias_dif_aqui == 0) ? 'orange' : (dias_dif_aqui < 0) ? 'red' : 'primary darken-3', 'pl-1 mr-4 mt-0 white--text ' ]"
        v-if="(show_boleto) && (2>1)" 
        @click="DSi_PAGAR_PIX(true)"
      >
      <!-- :class="[(boleto_atrasado) ? 'orange': 'primary darken-3', 'pl-1 mr-4 mt-0 white--text ' ]" -->
      <!-- deep-orange accent-3 -->
      <!-- :class="[(boleto_atrasado) ? 'blue darken-3': 'blue darken-3', 'pl-1 mr-4 mt-0 white--text ' ]" -->
      <!-- white black--text pl-2 -->
      
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <a class="white--text" @click="DSi_PAGAR_PIX(true)"
              v-bind="attrs"
              v-on="on"
          >
          <span style=" text-align: right; font-size: 10px;" class="white--text pa-0 mt-2 ml-0"> 
            <span v-text="(dias_dif_aqui == 0) ? 'hoje':'dia '+ dia_vence_aqui">  </span>
          </span>  
          BOLETO 
        </a>

        </template>
        <span> Vencimento dia {{ dia_vence_aqui }}  </span>
      </v-tooltip>


      

        <v-icon 
          color="white" 
          :size="(isMobile) ? 25: 30"
          class="mr-1"
          @click="DSi_PAGAR_PIX(true)"
        >
           mdi-barcode
        </v-icon>
         <!-- BOLETO⠀ -->
         <!-- PIX DSI⠀ -->
      </v-badge>



      <!-- ABRE CHAT -->
      <!-- <v-badge
        :content="count_chat"
        :value="count_chat"
        color="red"
        overlap
        :dot="(isMobile)"
        class="mr-3"
        @click="f_show_chat()"
      >
      
        <v-icon 
          color="white" 
          @click="f_show_chat()"
          :size="(isMobile) ? 25: 28"
        >
          mdi-chat-processing
        </v-icon>
      </v-badge> -->


      <!-- ABRE LEMBRETES -->
      <v-badge
        :content="count_lembrete"
        :value="(count_lembrete>0) ? count_lembrete : ''"
        color="red"
        overlap
        :dot="(isMobile)"
        class="mr-5"
        @click="f_show_lembretes()"
      >
      
        <v-icon 
          color="white" 
          @click="f_show_lembretes()"
          :size="(isMobile) ? 25: 27"
        >
        mdi-bell
        </v-icon>
      </v-badge>
      
    

      <v-menu
        bottom
        right
        min-width="180"
      >
        <template v-slot:activator="{ on, attrs }">



          <v-icon dark 
            v-bind="attrs"
            v-on="on"
            small
            @click="monta_menus_avatar('users')"
            :color="color_super"
          >
            <!-- mdi-dots-vertical -->
            mdi-chevron-down 
          </v-icon> 

          <v-avatar
            :size="(isMobile) ? 25: 30"
            color="grey lighten-3"
            v-bind="attrs"
            v-on="on"
            @click="monta_menus_avatar('users')"
          >
            <span class="primary--text font-weight-bold"> {{user_name_abreviado}} </span>
          </v-avatar>




        </template>

        <v-list dense>

          <v-subheader class="grey lighten-3">
            <v-icon> mdi-account-circle</v-icon>
            <h4 class="ml-1"> {{ user_name }} </h4>
          </v-subheader>
          
          <v-divider class="mx-0"></v-divider>


          <!-- troca de unidade -->
          <v-list-item
            v-for="(item, i) in items_avatar"
            :key="i"
            @click="acao_menu_avatar(item.click)"
          >
            <v-list-item-title>
                {{ item.title }}
            </v-list-item-title>
            
            <v-list-item-icon>
               <v-icon :color=item.icon_color> {{ item.icon }} </v-icon>
            </v-list-item-icon>
          </v-list-item>


          <v-divider class="mx-0"></v-divider>

          <!-- <v-list-item
            link
            class="white lighten-5"
            @click="atualiza_pagina_F5()"
            >
                <v-list-item-title>Atualizar  (F5)</v-list-item-title>
                <v-list-item-icon>
                <v-icon color="primary"> mdi-refresh </v-icon>
                </v-list-item-icon>
                
            </v-list-item> -->

            <!-- <v-divider class="mx-0"></v-divider> -->

            <v-list-item
            link
            class="grey lighten-5"
            @click="logout()"
            >
                <v-list-item-title>Sair</v-list-item-title>
                <v-list-item-icon>
                <v-icon color="red"> mdi-power-standby </v-icon>
                </v-list-item-icon>
                
            </v-list-item>

        </v-list>
      </v-menu>
      
      
      <!-- <v-btn
      text
      color="white"
       
       @click="sair()">  
        Sair 
        <v-icon>mdi-power-standby</v-icon>
      </v-btn> -->

      </v-app-bar>

      <!-- aqui no class tinha um mt-1 isso dava erro de na hora de imprimir sair 2 paginas...erro bobo -->
    <v-main>
      <router-view 
        @executar="changeDrawer" 
        @executar2="ler_session"
      >
     </router-view>
      <!-- @carregar_componentes="carregar_componentes" -->



      <!-- {{ dados_cliente }} -->



    <v-dialog v-model="dialog_ativar" width="900" persistent >


      <v-card class="">
        <v-card-title class="text-h5 orange lighten-2">
          Período de Teste Terminou
        </v-card-title>

        <v-card-text class="mt-4">
          <h3> Para continuar, selecione uma das opções abaixo:</h3>
          <br>
          <!-- <br> -->
          <!-- <span class="red--black"> Obrigado por Testar  <b> agilizar o acesso</b>, selecione a forma de pagamento <b>PIX</b>, e tente abrir o App em alguns minutos.</span> -->

        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            small
            text
            @click="dialog_ativar=false"
            v-if="0>1"
          >
            cancelar
          </v-btn>

          <v-btn
            color="error"
            small
            text
            @click="ativar_sistema('CANCELAR')"
            class="mr-10"
          >
            Cancelar Sistema
          </v-btn>

          <v-btn
            color="primary"
            small
            @click="ativar_sistema('ATIVAR')"
          >
            Continuar com o Sistema
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-dialog v-model="dialog_bloqueado" width="900" persistent >
      <v-card class="">
        <v-card-title class="text-h5 orange lighten-2">
          Fatura Pendente
        </v-card-title>

        <v-card-text class="mt-4">
          <h3> Não identificamos o pagamento de sua fatura do dia  <b class="blue--text">{{ bloqueado_vencimento }}</b> no valor de <b class="blue--text">R$ {{ bloqueado_valor }}</b>.</h3>
          <br>
          <br>
          <span class="red--black"> Para <b> agilizar o acesso</b>, selecione a forma de pagamento <b>PIX</b>, e tente abrir o App em alguns minutos.</span>

        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            small
            text
            @click="dialog_bloqueado=false"
            v-if="(souDSi)"
          >
            cancelar
          </v-btn>

          <v-btn
            color="error"
            small
            text
            @click="logout()"
          >
            fechar
          </v-btn>

          <v-btn
            color="primary"
            small
            @click="DSi_PAGAR_PIX(true)"
          >
            efetuar pagamento
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    </v-main>

    <!-- MOSTRA LEMBRETES -->
    <div v-if="(app_bar) &&(2>1)" >
      <v-row class="mt-n10">
        <v-col>
        <LembreteVue 
        v-if="(verificou_DSi)"
        :aberto = 'show_lembretes'
        pro_tabela = 'ALERTA'
        pro_titulo = 'Lembretes'
        :pro_obj_1 = null
        :pro_obj_items = null
        :show_menu = 'false'
        @muda_aberto_lembretes="muda_aberto_lembretes"
        @muda_count_lembretes="muda_count_lembretes"
        />
        </v-col>
      </v-row>
    </div>




    <!-- MOSTRA CHAT -->
    <!-- <div v-if="(app_bar)&&(2>1)">
      <v-row>
        <v-col>
        <ChatVue 
        v-if="(verificou_DSi)"
        :aberto = 'show_chat'
        @muda_aberto="muda_aberto"
        @muda_count="muda_count"
        />
        </v-col>
      </v-row>
    </div> -->


  </v-app>
</template>

<script>


  import axios from "axios";
  import DSirotinas from './assets/js/DSi_rotinas'//chamo funcao do arquivo
  import { define_data_hora, diferenca_datas } from './assets/js/DSi_basico'


  DSirotinas.url_app()


  export default {


    components:{
      // ChatVue,
      // LembreteVue

      // IMPORTO COMPONENTES AQUI NAO PRECISA DECLARAR EM CIMA.. E NAO GERA ERRO DO URL e so chamo apos logado
      // ChatVue:     () => import("./views/chat.vue"),
      LembreteVue: () => import("./views/Lembretes.vue")
      // https://stackoverflow.com/questions/55581345/dynamic-importing-components-based-on-route
    },

  
    data: () => ({ 
      
      versao: 'v 2.1.50',

      SOUoA:false,
      souDSi: false,

      verificou_DSi: false,
      verificou_permissoes: false,

      color_super: '',

      show_boleto: false,
      boleto_atrasado: false,
      dialog_bloqueado: false,
      dialog_ativar: false,

      bloqueado_valor:'',
      bloqueado_vencimento:'',

      dados_cliente: {},
      assinaturas: null,
      cliente_situacao :'ATIVO',

      dia_vence_aqui:'',
      dias_dif_aqui:0,
      dias_testando:0,

      show_lembretes: false,
      show_chat: false,

      count_chat:0,
      count_lembrete:0,

      items_avatar:[],  
      users_avatar: false,  

      esconde: true,
      drawer: false,
      app_bar: false,

      isMobile: false, 

      user_name:'',
      user_name_abreviado:'',
      empresa: '',
      session_bd : 'null',

      // src_img:'http://localhost/dsi.png',
      src_img:'https://dsi.app.br/dsi.png',

      items: [], 

      painel_fixo:false,  

      }),

    mounted(){
      this.meuTimer()
    },

   created(){
    this.onCreated()
   },

    
   watch: {
     
    //  empresa(){
    //    let agora  = new Date();
    //    localStorage['watch : Sessão Expirou em :'] = agora
    //    localStorage['Era para Expirar em  :'] = sessionStorage['expira']

    //    alert('Sessão Expirou');
    //    this.Logout()
    //  },

     drawer(){
      //  console.log('drawer:');
      //  console.log(this.drawer);
     },
     
     session_bd(a){
        sessionStorage['session_bd'] = a.trim()
     },

     
   },


    methods:{


      atualiza_pagina_F5(){
          location.reload()
      },

      async ativar_sistema(acao){

        let sql      = ''
        let situacao = acao
        let ide_cliente = this.dados_cliente.dados_cliente.CODIGO
        var url =  sessionStorage['url']
        

        if (!ide_cliente){
          alert('Código Inválido!')
          return false
        }

        if (confirm(`Deseja mesmo ${acao} o Sistema?`)){


          this.dialog_ativar = false

          if (acao == 'CANCELAR'){
            situacao = 'CANCELADO'
          }
          else if (acao == 'ATIVAR'){
            situacao = 'ATIVO'
          }

          sql = `update clientes set SITUACAO = '${situacao}' where codigo = ${ide_cliente};`
          sql+= `update assinaturas set SITUACAO = '${situacao}' where CODIGO_CLIENTE = ${ide_cliente};`
          console.log('sql:', sql);

          let res = ''

          if (res = await this.crud_sql_canal_extra(url, sql)){
            console.log('res:', res);

            alert('Concluído')
            await this.logout()
          }
        
        }

      },


      async  crud_sql_canal_extra (url, sql) {

        const res = await axios.post(url+'clientes/canal.php', {
            acao:   6,
            meuSQL: sql
        })

        console.log(res.data);
        return res.data
      },

      async onCreated(){

        // TITULO DO PROGRAMA
        document.title = 'DSi CFC'

        //  this.ler_php()
         sessionStorage['versao'] = this.versao

         this.isMobile = window.innerWidth < 600
         this.monta_menus_avatar('padrao')

         DSirotinas.SEM_F12()
         this.SOUoA = sessionStorage['lg:USUARIO'] == 'A'
      },



      // passo 1
      monta_menu_lateral(){

        //busco objeto no sessionStorage
        this.obj_user = JSON.parse(sessionStorage['obj_user'])
        console.log( 'obj_user:',  this.obj_user);

        this.souDSi = (sessionStorage['souDSi'] == 'S') ? true : false


        this.items = []


        this.items.push(
            // { title: 'Home', icon: 'mdi-view-dashboard', to: '/home' },//mdiSitemap 
            // { title: 'Acontecendo', icon: 'mdi-text-box-multiple ', to:'/news' },
            // { title: 'Agenda', icon: 'mdi-calendar-clock ', to: '/agenda' },
            // { index:0, title: 'Inicio', icon: 'mdi-home-analytics ', color:'red', to:'/home' },
            { index:1, title: 'Cadastro', icon: 'mdi-account', color:'', to:'/cadastro' },
        )

        if ( (this.obj_user.SUPER=='S') || (this.obj_user.FINANCEIRO_R=='S') || (this.obj_user.FINANCEIRO_R=='L')|| (this.obj_user.FINANCEIRO_R=='B')){
          this.items.push(
            { index:2, title: 'Financeiro', icon: 'mdi-chart-bar ',color:'', to:'/financeiro' },
          )
        }

        if (this.souDSi){
          
          // habilitar isso ou nao via modulo??
          // sessionStorage['agenda_show'] = 'S'

          if (this.obj_user.SUPER=='S'){
              this.items.push(
                { index:3, title: 'DSi', icon: 'mdi-home',color:'', to: '/dsi', janela:'dsi' },  // mdi-google-downasau
                // { index:4, title: 'Agenda',  icon: 'mdi-calendar-clock',color:'', to: '/agenda', janela:'agenda' },//mdi-view-list
              )
          }
        }

        

        sessionStorage['agenda_show'] = 'S'
        this.items.push(
            { index:4, title: 'Agenda',  icon: 'mdi-calendar-clock',color:'', to: '/agenda', janela:'agenda' },//mdi-view-list
        )


        if ( (this.obj_user.SUPER=='S') || (this.obj_user.TABELAS_R=='S')){
          this.items.push(
            { index:4, title: 'Configurações', icon: 'mdi-cogs',color:'', to: '/tabelas', janela:'tabelas' },//mdi-view-list
            // { index:4, title: 'Tabelas', icon: 'mdi-view-list',color:'', to: '/tabelas', janela:'tabelas' },//mdi-view-list
          )
        }

        // if (this.obj_user.SUPER=='S'){
        //   this.items.push(
        //     { index:5, title: 'Configurações', icon: 'mdi-cog', color:'',to: '/configuracoes', janela: 'configuracoes' }, //mdi-folder-cog
        //   )
        // }

      },


      muda_aberto_lembretes(valor){
        this.show_lembretes = valor
      },

      muda_count_lembretes(valor){
        this.count_lembrete = valor 

        //aproveito essa função que executa somente apos logado
        this.refresh_app()
      },

      f_show_lembretes(){
        this.show_lembretes =!this.show_lembretes
      },

      f_show_chat(){
        this.show_chat =!this.show_chat
      },

      muda_aberto(valor){
        this.show_chat = valor
      },

      muda_count(valor){
        this.count_chat = valor 

        if (valor>0){
          document.title = `(${valor}) `+ sessionStorage['emp_title']
        }
        else{
          document.title = sessionStorage['emp_title']
        }
        

        //aproveito essa função que executa somente apos logado
        // this.refresh_app()
      },

      async refresh_app(){
        console.log('Refresh App.vue: cadastro e financeiro');

        if (!this.verificou_permissoes){
          this.monta_menu_lateral()
          this.verificou_permissoes = true
        }

        if (!this.verificou_DSi){
          
          this.DSi_GERAR_PARCELA()
          this.verificou_DSi = true
        }

      },

      async DSi_PAGAR_PIX(ver_boleto){

        console.log('DSi_PAGAR_PIX:');

        let IDE =  sessionStorage['emp_ide'];

        let link = ''
        let teste = sessionStorage['url'];
        if (teste == '/api/'){
          link = `http://localhost/vue_cfc/public/clientes/?token=pi45i${IDE}`//teste
        }
        else{
          link = `https://dsi.app.br/f/c/clientes/?token=pi45i${IDE}`//produção
        }

        const res = await axios.get(link);
        // console.log(res);
        console.log(res.data);

        //isso daqui é lido la em App.vue
        sessionStorage['boleto_mostrar']  = 'S'
        sessionStorage['boleto_atrasado'] = '?'

        if (res.data.id_pix){//gerou ok

          if (ver_boleto){
             window.open(res.data.pagina_retorno, '_blank');
          }


          //caso clique em pagar.. gera um timer para ver se pagou ou nao
          setTimeout(function(){
            this.DSi_GERAR_PARCELA()  
            console.log('TIMER: DSi_GERAR_PARCELA');
          }.bind(this), 120000);//atualiza em 2 minutos

        }

      },

      async DSi_GERAR_PARCELA(){

        // BUSCO DADOS DO CLIENTE.. E DA PARCELA

        define_data_hora(this)//passo o this para que la ele defina as variaveis daqui

        // console.log('DSi_GERAR_PARCELA:');

        let IDE =  sessionStorage['emp_ide'];

        let link = ''
        let teste = sessionStorage['url'];
        if (teste == '/api/'){
          link = `http://localhost/vue_cfc/public/clientes/?token=ci45i${IDE}`//teste
        }
        else{
          link = `https://dsi.app.br/f/c/clientes/?token=ci45i${IDE}`//produção
        }

        const res = await axios.get(link);

        console.log('DSi_GERAR_PARCELA:', res.data);


        await fazer(this, res.data)

        async function fazer(that, dados){

          // console.log('fazer:',dados);
          sessionStorage['obj_dados_cliente']  =  JSON.stringify(dados.dados_cliente)

          that.dados_cliente        = dados

          that.cliente_situacao     = dados.dados_cliente.SITUACAO
          that.bloqueado_vencimento = dados.dados_parcela?.x_DATA_VENCIMENTO
          that.bloqueado_valor      = dados.dados_parcela?.double_VALOR
          that.dialog_bloqueado     = (dados.bloquear == 'S')

          console.log('gerar_pix_id:', dados.gerar_pix_id);



          // verifica se cliente esta testando..conta quantos dias faltam
          let dd_teste = 0
          if (that.cliente_situacao == 'TESTE'){
            
            dd_teste =  await diferenca_datas(that.today, dados.dados_cliente?.DATA_TESTE_ACABA)
            that.dias_testando = dd_teste

            if (dd_teste<=0){
              that.dialog_ativar = true
            }

          }

          if (dados.gerar_pix_id == 'S'){
              //  that.DSi_PAGAR_PIX(false) //gera a parcela pix 
          }

        
          //pega dados das assinaturas..procura modulo zap
          // let assinaturas = ''
          let zapzap      = {}
          if (dados.dados_assinatura){

            console.log('assinaturas:', dados.dados_assinatura);
            that.assinaturas = dados.dados_assinatura

            // alert(dados.dados_assinatura.length)

            zapzap =  that.assinaturas.map((x) => x); //clonando array copiar array

            zapzap = zapzap.filter(x => x.SERVICO === 'MODULO WHATSAPP'); //clonando array copiar array
            // zapzap = this.assinaturas.filter(x => x.SERVICO === 'MODULO WHATSAPP'); //clonando array copiar array
            zapzap = zapzap[0]

            console.log('zapzap:', zapzap);
          }

          if (zapzap){
            sessionStorage['config_whats']  =  JSON.stringify(zapzap)
            sessionStorage['zapzap_mostrar'] = true
          }
          else{
            sessionStorage['config_whats']  =  JSON.stringify(false)
            sessionStorage['zapzap_mostrar'] = false
          }

        }


        

          

        let dia_vence = 0
        if (res.data.dados_parcela?.DATA_VENCIMENTO ){//pega pelo vencimento
          let aux = res.data.dados_parcela?.DATA_VENCIMENTO
          aux = aux.substring(8,10)
          dia_vence = parseInt(aux)
        }
        else{
          dia_vence = res.data.dados_cliente?.DIA_VENCIMENTO //senao pega pela dava vencimento salvo no cadastro do cliente
        }

        // sessionStorage['dia_vence'] = dia_vence
        this.dia_vence_aqui = dia_vence
        // this.dia_vence_aqui = res.data.dados_parcela?.x_DATA_VENCIMENTO


        let pago      = (res.data.dados_parcela?.PAGO == 'S') ? 'S' : 'N'
        let dia_hoje  = this.dia_hoje
        let dias_dif = 0
        let mostrar = 'N'
        let atrasado = res.data.atrasado

        console.log('pago:'+pago);

        if (pago == 'S'){
            mostrar = 'N'
        }
        else {

          dias_dif = parseInt(dia_vence) - parseInt(dia_hoje)
          this.dias_dif_aqui = await diferenca_datas(this.today, res.data.dados_parcela?.DATA_VENCIMENTO)

          if (atrasado == 'S'){
              mostrar = 'S'
          }
          else{
              console.log('dia_vence: '+dia_vence+' hoje: '+this.dia_hoje + ' dias_dif: '+dias_dif)
            

              // somente mostra boleto se cliente estiver ATIVO
              if ((dias_dif <=10) && (dias_dif >=0) && (res.data.dados_cliente.SITUACAO == 'ATIVO') ){
                console.log('PODE MOSTRAR');
                mostrar = 'S'
              }
          }

        }

        if (mostrar){
           if (this.dados_cliente.dados_assinatura.length <1){
            mostrar = 'N' 
           }
        }


        // console.log('mostrar:'+ mostrar);
        // console.log('atrasado:'+ atrasado);

        // SOMENTE SE FOR SUPER USER
        if ( sessionStorage['lg:S'] == 'S'){
          this.show_boleto     = (mostrar=='S') ? true : false
          this.boleto_atrasado = (atrasado == 'S') ? true : false

          this.color_super = 'black'
        }
        else {
          this.show_boleto     = false
          this.boleto_atrasado = false
          this.color_super = ''
        }
        // res.data.bloquear

      },


      monta_menus_avatar(qual){


        if (qual == 'padrao'){
          // this.items_avatar.push( { title: 'Sair', icon: 'mdi-power-standby', icon_color: "red", click: 'sair' } )
        }
        else if (qual == 'users'){

            if (!this.users_avatar){
            //  SOMENTE 1 VEZ

            if (sessionStorage['box']){
              
              let box = sessionStorage['box']
              this.users_avatar = true

              let users = JSON.parse(box);

              // console.log(users);

              if (users?.length>1){

                for (let i = 0; i < users?.length; i++) {
                    if (users[i].EMPRESA_CODIGO != sessionStorage['lg:IDE']){//nao coloca o DB atual
                       this.items_avatar.unshift( { title: users[i].EMPRESA_NOME, icon: 'mdi-cube-outline', icon_color: "blue", click: {'acao' :'change', valor : users[i]} } )
                    }
                }
              }
            } 
          }
        }
      },


      acao_menu_avatar(item){
        // if (acao=='sair'){
        //   this.sair()
        // }
        // console.log(item);

        // console.log(item.acao);
        if (item.acao == 'change'){
          // console.log(item.valor)

            console.log('TROCA EMPRESA');
            // alert('TROCA EMPRESA')

            sessionStorage['config_pix']    = ''
            sessionStorage['config_whats']  = ''

            // Agenda.vue
            sessionStorage['veiculo_selecionado']    = ''
            sessionStorage['instrutor_selecionado']  = ''
            sessionStorage['cadastro_selecionado']   = ''

            // Cadastro.vue
            sessionStorage['ja_count_niver']         = 0

            this.verificou_permissoes = false
            this.verificou_DSi = false
            this.items_avatar = []
            this.users_avatar = false //atualiza os menus de box
            sessionStorage['box_selecionado'] = JSON.stringify(item.valor)
            
            // location.reload();//F5 na pagina para puxar atualizacao
            this.$router.push('/')//redireciono para a pagina do router
        
        }
        
      },

      abrevia_nomes(entra,coringa){
        let partes = entra.split(coringa);

        // console.log(partes);

        let A = ''
        let B = ''

        A = partes[0][0]
        B = ''
        
        if (partes.length>=2){
          B = partes[1][0]
        }
        
        let r = ''

        if (A){ r  = A}
        if (B){ r += B}

        return r
      },


      navega(){
        let href="https://sistemasdsi.com.br/"
        window.open(href,'_blank');
      },


      async logout(){
        // TEM OUTRO SAIR QUE FICA NO DSIBASICO, USO LA PRA SAIR QUANDO ESTA LOGOUT

        //  this.$router.push('/')//redireciona para a pagina login


        sessionStorage['buffer']        = 'SS'
        sessionStorage['refresh_size']  = 'wMPGu87'
        sessionStorage['logout']        = 'S'

        this.dialog_bloqueado = false

        this.$router.push('/')//redireciona para a pagina login



        // sessionStorage['fez_logout'] = 'S'
        // sessionStorage['created_cadastro'] = 'N'

        // const res = await axios.post(url+'logado.php', {
        //   acao : 'logout'
        // })
        //  window.location.href = url;
      } ,

      changeDrawer (newDrawer) {

        this.app_bar = newDrawer

        // alert(newDrawer)
        if (this.$vuetify.breakpoint.mdAndDown){
          this.drawer = false
          // alert('md and down')
        }else{
          this.drawer = newDrawer
        }

        

        if (newDrawer == true){
          this.esconde = false
        }
        else{
          this.esconde = true
        }
      },

      async ler_session(){//estou chamando isso no logado() da agenda.vue via emit

        // EXECUTA SOMENTE 1 VEZ

        // if (this.empresa != sessionStorage['emp_nome']){

          this.user_name = this.read_sessionStorage('lg:USUARIO')

          this.user_name_abreviado = this.abrevia_nomes(this.user_name,' ')

          this.src_img = sessionStorage['url_base'] +'/dsi.png'

          // this.empresa = sessionStorage['lg:EMPRESA']
          this.empresa = sessionStorage['emp_nome']


          this.refresh_app()

          // alert('app.vue ler_sessoion')
        // }

      },

     read_sessionStorage($name,$to){
        if (sessionStorage[$name]) {
           return sessionStorage[$name]
        } 
     },


      meuTimer(){

      setTimeout(function(){

      }.bind(this), 100);//usar esse .bind(this) para chamar funcoes com o setTimeout
      },
    }
  }
</script>

<style>


@media print {
    .no-print {
      display: none !important;
    }

    .v-app,
    .v-footer,
    .v-navigation-drawer,
    .v-card-actions,
    .v-toolbar {
      display: none !important;
    }

 }

 
 .hand:hover {
  cursor: pointer; /* Muda o cursor para a mão ao passar o mouse sobre o container */
 }

</style>