
import axios from "axios";

var url =  sessionStorage['url']
url += 'clientes/canal.php'



//FICA NO BOTAO SALVAR.. VERIFICA SE UPDATE OU CREATE
export async function  salvar_tabela_45(that,tabela, obj_edita, dialog_name){

    if (dialog_name){
        that[dialog_name] = false
    }

    // console.log('salvar_tabela:',obj_edita);

    //GLOBAL
    if (obj_edita.CODIGO >0 ){
        // that.tabela_update(tabela, that.item_edita)
        await that.tabela_update(tabela, obj_edita)
    }else{
        await that.tabela_create(tabela, obj_edita)
    }
};

export async function editar_tabela_45(that, item, obj_edita_name, dialog_name) {
    // that.items_clicou = item //ver se vou usar isso aqui
    that[obj_edita_name] = Object.assign({}, item)
    that[dialog_name] = true
};


export async function remover_tabela_45(that, tabela, obj_edita, titulo){
    //GLOBAL
    // console.log(obj_edita);
    let ide = obj_edita.CODIGO
    if (ide > 0){
        if (confirm("Deseja realmente excluir "+titulo+' - '+ide+' ?')){

            let sql = 'delete from '+tabela+' where codigo ='+ide
            await that.crud_sql(sql)

            await that.tabela(tabela,'delete')
        }
    }
};


//EXECUTAR UM SQL TIPO UPDATE
export async function crud_sql_45 (that, sql, show_alerta ) {

    const res = await axios.post(url,{
        acao:   6,
        meuSQL: sql
    })

    // console.log(res.data);
    if (show_alerta!='N'){
       that?.alerta(res.data, "success");//so retorna ok ou error
    }
};


//ABRE OS DADOS DA TABELA
export async function crud_abrir_tabela_45(that, sql, items_name){
    //GLOBAL    
    // console.log('crud_abrir_tabela_45:', sql);

    if (items_name){
        that[items_name] = []//limpa antes
    }

    var meuSQL = sql
    // console.log(meuSQL, db);
            
    const res = await axios.post(url, {
        acao:   5,
        meuSQL: meuSQL
    })

    if (items_name){
        that[items_name] = await res.data 
        // console.log(that[items_name]);
        // console.log('c2',items_name);
    }

    // console.log('crud_abrir_tabela:', res.data);
    return await res.data
};


export async function tabela_update_45(that, tabela, obj_edita){
    //GLOBAL

    let id = obj_edita.CODIGO
    if (id<1){
    that.alerta('Código inválido!','red')
    }
    else{

        const data = {
                acao: 3,
                id:           id,
                id_field:    'CODIGO', 
                table:       tabela,
                fields:      obj_edita,
        }  

        const res = await axios.post(url, data)

        that.alerta(res.data, "success");
        that.tabela(tabela,'update')//close open

        // console.log('tabela_update:', res.data)
    }
};

export async function tabela_create_45(that, tabela, obj_edita){

    const data = {
            acao: 2,
            table:             tabela,
            fields:            obj_edita,
    }  

    const res = await axios.post(url, data)
    // console.log('tabela_create:', res.data);

    that?.alerta(res.data, "success");
    that?.tabela(tabela,'create')//close open
    
    // console.log('tabela_create:', res.data)

};



//EXECUTAR UM SQL TIPO UPDATE
export async function executar_comando_45 ( acao, comando ) {


    let url_here  = sessionStorage['url']
    

    if (acao == 'RENOVAR_CERTIFICADO'){
comando = `ABRIR|C:\\Users\\Administrator\\Desktop\\RENOVAR CERTIFICADO CERTBOT.BAT`
    }

    else if (acao == 'REINICIAR_ZAP'){

comando   =  
`
FECHAR|conhost.exe\r\n
FECHAR|node.exe\r\n
ABRIR|C:\\xampp\\htdocs\\vue_zap\\start.bat\r\n
`


// `
// FECHAR|mspaint.exe\r\n
// FECHAR|notepad.exe\r\n
// ABRIR|C:\\Windows\\system32\\notepad.exe\r\n
// `


// FECHAR|winword.exe\r\n
// ABRIR|C:\\Program Files\\Microsoft Office\\root\\Office16\\WINWORD.EXE\r\n
// ABRIR|C:\\WINDOWS\\system32\\mspaint.exe\r\n
        }



        // mando a propria acao que sera tratado em exec_app.php
        const res = await axios.post(url_here+'exec_app.php', {
            acao      :  acao,
            comando   :  comando,
        })

        // console.log('retorno:', res.data);
        return res.data
        
    

        


};



export default {
    salvar_tabela_45,
    editar_tabela_45,
    remover_tabela_45,
    tabela_update_45,
    tabela_create_45,
    crud_sql_45,
    crud_abrir_tabela_45,
    executar_comando_45
};


